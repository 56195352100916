import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export const calculateVisiblePosts = (): number => {
  const theme = useTheme();

  const isMediumViewPort = useMediaQuery(theme.breakpoints.between('sm', 'xl'));
  const isLargeViewPort = useMediaQuery(theme.breakpoints.up('xl'));
  const isTheBiggestViewPort = useMediaQuery(theme.breakpoints.up(1920));

  let VISIBLE_POSTS = 1;
  if (isMediumViewPort) VISIBLE_POSTS = 2;
  if (isLargeViewPort) VISIBLE_POSTS = 3;
  if (isTheBiggestViewPort) VISIBLE_POSTS = 4;

  return VISIBLE_POSTS;
};
