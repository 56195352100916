import { FC, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { clsx } from 'clsx';
import { useRouter, useSearchParams } from 'next/navigation';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CmsContentResponse } from '@creator-portal/common/types';

import { getCmsLink, ShortNews } from '@/services/cms/cms-services';
import { Time } from '@/services/common/Time';

import { BlogInfo } from '@/pages/welcome';

import * as Xhr from '@/util/xhr';

import useStyles from './CarouselBlog.styles';

import 'swiper/css';
import 'swiper/css/navigation';

const xhr = Xhr.getInstance();

interface CarouselBlogProps extends BlogInfo {
  visiblePosts: number;
  locale: string;
}
const CarouselBlog: FC<CarouselBlogProps> = ({ visiblePosts = 1, blogs, blogTotal, locale }) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const classes = useStyles();
  const theme = useTheme();

  const [direction, setDirection] = useState<'rtl' | 'ltr' | undefined>(theme.direction);
  const [articles, setArticles] = useState<ShortNews[]>(() => blogs);

  useEffect(() => {
    setDirection(theme.direction);
  }, [theme.direction]);

  const handleDownloadMore = async () => {
    if (articles.length === blogTotal) return;

    try {
      const url = getCmsLink(4, locale, articles.length);
      const response = await xhr.fetchJson<CmsContentResponse>(url);
      Xhr.throwOnFailure(response);
      let blogList: ShortNews[] = [];
      if (response?.data?.blogList) {
        blogList = response.data.blogList.map((el) => ({
          title: el.title,
          _id: el._id,
          date: el.date,
          trendingImage: el.trendingImage ? el.trendingImage : null,
          image: el.image ? el.image : null,
          slug: el.slug,
        }));
      }

      setArticles((prev) => [...prev, ...blogList]);
    } catch (error) {
      console.log('error while downloading additional articles');
    }
  };

  const articleCards = articles.map((el) => {
    const date = <Time timestamp={el.date} format="date" />;
    const img = el.image || el.trendingImage || '';

    const teamId = searchParams?.get('team');

    const baseUrl = `/news/${el.slug}?isWelcome=true`;
    const link = teamId ? `${baseUrl}&team=${teamId}` : baseUrl;

    return (
      <SwiperSlide onClick={() => router.push(link)} key={el._id} className={classes.swiperCardWrapper}>
        <div className={classes.newsItem}>
          <div className={classes.newsImg} style={{ backgroundImage: `url(${img})` }}></div>
          <Typography className={classes.newsItemSubTitle} variant="h5">
            {date}
          </Typography>
          <Typography className={classes.newsItemTitle} variant="h6">
            {el.title}
          </Typography>
        </div>
      </SwiperSlide>
    );
  });

  return (
    <div className={classes.swiperWrapper}>
      {direction && (
        <Swiper
          dir={direction}
          navigation={true}
          slidesPerView={visiblePosts}
          spaceBetween={30}
          slidesPerGroup={visiblePosts}
          loopFillGroupWithBlank={true}
          modules={[Navigation]}
          className={clsx('mySwiper', classes.swiper)}
          onReachEnd={handleDownloadMore}
        >
          {articleCards}
        </Swiper>
      )}
    </div>
  );
};

export default CarouselBlog;
