import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { clsx } from 'clsx';
import { Trans, useTranslation } from 'next-i18next';
import Image from 'next/image';

import { getEpicsPicksApplyNowUrl, getEpicsPicksUrl } from '@/config/common/functions';

import Button from '@/components/ui/Button';

import { useAuthSession } from '@/hooks/useAuthSession';
import { trackClickInteraction } from '@/util/tracking';

import OpenIconBlack from '@/public/enroll/icons/external_open_black.svg';
import OpenIcon from '@/public/enroll/icons/external_open.svg';

import CommonLink from '../common/common-link';
import useStyles from './picks-banner.styles';

export default function PicksBanner(): JSX.Element | null {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const user = useAuthSession();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const handleApplyButtonClick = () => {
    trackClickInteraction({
      eventCategory: 'EpicsPicks',
      eventAction: 'ApplyNow',
      eventLabel: 'WelcomePage',
      portalAccountId: user?.accountId,
    });
  };

  return (
    <Box className={clsx(classes.banner)}>
      <div className={classes.imageWrapper}>
        <div className={classes.image} />
      </div>
      <div>
        <h3 className={classes.header}>{t('welcome.banner.picks.header')}</h3>
        <div className={classes.content}>
          <Trans
            i18nKey="welcome.banner.picks.content"
            components={{
              a: <CommonLink href={getEpicsPicksUrl(i18n.language)} target="_blank" rel="noopener noreferrer" />,
            }}
          />
        </div>
      </div>
      <Button
        className={classes.btn}
        variant="outlined"
        href={getEpicsPicksApplyNowUrl(i18n.language)}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleApplyButtonClick}
      >
        {t('button.apply-now')}
        <Image alt="icon" src={isDarkMode ? OpenIcon : OpenIconBlack} />
      </Button>
    </Box>
  );
}
