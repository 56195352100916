import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useCommonResponsiveStyles = makeStyles((theme) => {
  const isRTL = theme.direction === 'rtl';

  return createStyles({
    responsive: {
      direction: theme.direction,
      margin: isRTL ? theme.spacing(0, 24, 0, 'auto') : theme.spacing(0, 24),
      maxWidth: '1640px',
      // @ts-ignore-next-line
      [theme.breakpoints.down(2078)]: {
        margin: theme.spacing(0, 16),
      },
      [theme.breakpoints.down('lg')]: {
        margin: theme.spacing(0, 11.2),
      },
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(0, 8),
      },
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0, 4.8),
      },
    },
  });
});

export default useCommonResponsiveStyles;
