import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import type { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alertsArea: {
      marginTop: theme.spacing(4),
      position: 'relative',
      zIndex: 1,
    },
    genericResponsive: {
      padding: theme.spacing(0, 4.8, 0),

      [theme.breakpoints.up(theme.breakpoints.values.xs)]: {
        padding: theme.spacing(0, 8, 0),
      },
      [theme.breakpoints.up(theme.breakpoints.values.md)]: {
        padding: theme.spacing(0, 11.2, 0, 15),
      },
      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        padding: theme.spacing(0, 16, 0),
      },
      [theme.breakpoints.up(theme.breakpoints.values.xxl)]: {
        padding: theme.spacing(0, 24, 0),
      },
    },
  }),
);

export default useStyles;
