import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import type { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => {
  const isRTL = theme.direction === 'rtl';

  return createStyles({
    banner: {
      direction: theme.direction,
      backgroundColor: theme.palette.shade7,
      display: 'flex',
      alignItems: 'center',
      borderRadius: '16px',
      flexDirection: 'column',
      padding: theme.spacing(4.8, 4),
      margin: theme.spacing(0, 10),
      color: theme.palette.shade0,
      gap: theme.spacing(4),
      minHeight: '140px',

      [theme.breakpoints.up(theme.breakpoints.values.md)]: {
        display: 'grid',
        gridTemplateColumns: '1fr 145px',
        padding: theme.spacing(4.8, 8),
      },
      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        gridTemplateColumns: '330px 1fr 145px',
      },
      [theme.breakpoints.up(theme.breakpoints.values.xl)]: {
        gridTemplateColumns: '350px 1fr 145px',
      },
      [theme.breakpoints.up(theme.breakpoints.values.xxl)]: {
        gridTemplateColumns: '350px 1fr 230px',
      },
    },
    header: {
      margin: 0,
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(33),
      fontWeight: theme.typography.fontWeightBold,
      marginBottom: theme.spacing(0.8),
    },
    content: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 400,
      lineHeight: '165%',
      letterSpacing: '0.28px',
    },
    imageWrapper: {
      display: 'none',
      height: '100%',
      position: 'relative',

      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        display: 'block',
      },
    },
    image: {
      bottom: theme.spacing(-4.8),
      width: isRTL ? '352px' : '365px',
      height: isRTL ? '145px' : '150px',
      position: 'absolute',
      backgroundSize: 'contain',
      backgroundImage: 'url(/artwork/epicPicks.png)',
      backgroundRepeat: 'no-repeat',
    },
    btn: {
      '&.MuiButtonBase-root.MuiButton-root': {
        color: theme.palette.shade0,
        borderColor: theme.palette.shade0,
        width: '100%',
        padding: theme.spacing(0, 2.4),
      },
      '& .EuiButton-innerLabel': {
        gap: '16px',
      },
    },
  });
});

export default useStyles;
