import * as React from 'react';
import { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { clsx } from 'clsx';
import { Trans, useTranslation } from 'next-i18next';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { makeNavPath } from '@creator-portal/common/util/makeNavPath';
import { canAccess } from '@creator-portal/common/auth';
import { PERMISSION } from '@creator-portal/common/permissions/constants';
import { PERSONAL_TEAM_ID } from '@creator-portal/common/publishing/constants';

import { calculateVisiblePosts } from '@/services/cms/calculateVisiblePosts';
import { getCreatorNewsArticles } from '@/services/cms/cms-services';
import { getAlerts } from '@/services/profile/profile-service';

import CarouselBlog from '@/components/Blog/CarouselBlog/CarouselBlog.component';
import InfoBox from '@/components/common/alerts/info-box';
import CouldBeBannedBanner from '@/components/common/banned.banner';
import CommonLink from '@/components/common/common-link';
import CreatorAlertComponent from '@/components/common/creator.alert';
import { ErrorBoundary } from '@/components/common/error-boundary';
import MainLayout from '@/components/common/main.layout';
import Button from '@/components/ui/Button';
import PicksBanner from '@/components/welcome/picks-banner';

import { useAuthSession } from '@/hooks/useAuthSession';
import { useCreatorProgramStatus } from '@/hooks/useCreatorProgramStatus';
import useLocalStorageKey from '@/hooks/useLocalStorageKey';
import { getServerSideTranslations } from '@/util/getServerSideTranslations';
import { getLocale } from '@/util/locale';
import { requireAuth } from '@/util/requireAuth';
import * as Xhr from '@/util/xhr';

import useStyles from './index.styles';

import type { ShortNews } from '@/services/cms/cms-services';
import type { CreatorAlert } from '@creator-portal/common/types';
import type { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';

export interface BlogInfo {
  blogs: ShortNews[];
  blogTotal: number;
  locale: string;
}

export async function getServerSideProps(ctx: GetServerSidePropsContext): Promise<GetServerSidePropsResult<BlogInfo>> {
  if (!ctx.query.team) {
    return {
      redirect: {
        destination: makeNavPath('/welcome', ctx.resolvedUrl, {
          searchParams: {
            team: PERSONAL_TEAM_ID,
          },
        }),
        permanent: true,
      },
    };
  }

  const locale = getLocale(ctx);
  const translations = await getServerSideTranslations(locale);

  const blogsResponse = await getCreatorNewsArticles(locale);

  const publicPepUrl = '/island-creator/overview';

  // get alerts has moved to client fetch to avoid any potential blocking due to service connection

  const state: GetServerSidePropsResult<BlogInfo & { publicPepUrl: string }> = {
    props: {
      locale,
      publicPepUrl,
      blogs: blogsResponse.shortNews,
      blogTotal: blogsResponse.total,
      ...translations,
    },
  };

  if (ctx.req.cookies.as_user) {
    const xhr = Xhr.getInstance(ctx);
    return await requireAuth(xhr, () => state);
  }

  return state;
}

export default function StudioWelcomeIndexPage({
  blogs,
  blogTotal,
  locale,
  publicPepUrl,
}: BlogInfo & { publicPepUrl: string }): JSX.Element | null {
  const [alerts, setAlerts] = useState<CreatorAlert[]>([]);
  const classes = useStyles();
  const { push, query } = useRouter();
  const { t } = useTranslation();
  const user = useAuthSession();
  const creatorProgramStatus = useCreatorProgramStatus();
  const banned = creatorProgramStatus?.banned;
  const visiblePosts = calculateVisiblePosts();
  const isIcpFeatureAvailable = canAccess(user, PERMISSION.ICP_FEATURE);
  const showAnalyticsLaunchAlertFlag = canAccess(user, PERMISSION.SHOW_ANALYTICS_LAUNCH_ALERT) && !banned;
  const showEpicsPicks = canAccess(user, PERMISSION.SHOW_EPICS_PICKS) && !banned;
  const loggedIn = !!user;

  useEffect(() => {
    if (loggedIn) {
      const setupAlerts = async () => {
        const creatorAlerts = await getAlerts(locale);
        setAlerts([...(creatorAlerts?.alerts || []), ...(creatorAlerts?.announcements || [])]);
      };
      setupAlerts().catch((e: unknown) => console.log(e));
    }
  }, [loggedIn, locale]);

  const handleClick = () => {
    if (!loggedIn) return void push('/auth/login');
    else return void push(isIcpFeatureAvailable ? '/enroll' : publicPepUrl);
  };

  let showButton = true;
  let buttonLabel = '';
  let subTitleText = '';

  if (creatorProgramStatus?.enrolled || creatorProgramStatus?.banned) showButton = false;
  if (creatorProgramStatus?.needsToEnroll) showButton = true;
  if (creatorProgramStatus?.resumeEnroll) showButton = false;

  if (loggedIn) {
    subTitleText = creatorProgramStatus?.needsToEnroll ? t('welcome.subtitle.enroll') : t('welcome.subtitle.is-enrolled');
    buttonLabel = t('button.enroll-now');
  } else {
    showButton = true;
    buttonLabel = t('navbar.login');
    subTitleText = t('welcome.subtitle.sign-in');
  }

  const isShownContinueEnrollmentAlert = isIcpFeatureAvailable && loggedIn && creatorProgramStatus?.resumeEnroll;
  const { value: isShownAnalyticsLaunchAlert, handleSetKey: handleSetAnalyticsLaunchAlertKey } = useLocalStorageKey('alertAnalyticsPlays');
  const alertAnalyticsLaunch = {
    type: 'info',
    severity: 'info',
    messages: [t('welcome.alert.analytics-launch')],
    closeable: true,
    popup: false,
  };

  return (
    <>
      <Head>
        <title>{t('welcome.seo.title')}</title>
        <meta name="description" content={t('welcome.seo.description')} />
        <meta name="twitter:title" content={t('welcome.seo.twitter.title')} />
        <meta name="twitter:description" content={t('welcome.seo.description')} />
      </Head>
      <MainLayout>
        <CouldBeBannedBanner />
        {loggedIn && showAnalyticsLaunchAlertFlag && !isShownAnalyticsLaunchAlert && (
          <Stack spacing={3} className={classes.alertsArea}>
            <CreatorAlertComponent alert={alertAnalyticsLaunch} onClick={() => handleSetAnalyticsLaunchAlertKey('true')} />
          </Stack>
        )}

        {alerts.length > 0 && (
          <Stack spacing={3} className={classes.alertsArea}>
            {alerts.map((alert) => (
              <CreatorAlertComponent key={alert.key || alert.type} alert={alert} />
            ))}
          </Stack>
        )}
        {isShownContinueEnrollmentAlert && (
          <Stack spacing={3} className={classes.alertsArea}>
            <InfoBox
              severity="info"
              className={classes.continueEnrollAlert}
              onSubmit={handleClick}
              submitButtonText={t('button.continue-enrollment')}
            >
              {t('welcome.alert.continue-enrollment')}
            </InfoBox>
          </Stack>
        )}

        <div className={clsx(classes.welcomeBlock, classes.contentTopPadding)} data-testd="welcome">
          <div className={classes.top}>
            <Typography className={classes.titleWrapper} variant="h1">
              <Trans
                i18nKey="welcome.title"
                components={{
                  span: <div className={classes.welcomeTitle} />,
                  strong: <div className={classes.title} />,
                }}
              />
            </Typography>
            <Typography className={classes.subTitle} variant="h2">
              {subTitleText}
            </Typography>
            {showButton && (
              <Button data-test="welcome-button" onClick={handleClick} className={classes.btn}>
                {buttonLabel}
              </Button>
            )}
          </div>
          <div className={clsx(classes.news, classes.marginForArabian)}>
            <Typography className={classes.newsTitle} variant="h3">
              {t('welcome.latest-news')}
            </Typography>
            <Typography className={classes.newsExpand} variant="h4">
              <CommonLink href={`/news${query?.lang ? `lang=${query.lang as string}` : ''}`}>{t('link.view-all')}</CommonLink>
            </Typography>
          </div>
          <ErrorBoundary>
            <CarouselBlog locale={locale} visiblePosts={visiblePosts} blogs={blogs} blogTotal={blogTotal} />
          </ErrorBoundary>

          {showEpicsPicks && <PicksBanner />}
        </div>
      </MainLayout>
    </>
  );
}
