import { alpha } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import getPaletteModeStyles from '@/util/getPaletteModeStyles';

const useStyles = makeStyles((theme) => {
  const isRTL = theme.direction === 'rtl';

  return createStyles({
    alert: {
      border: `1px solid ${theme.palette.shade4}`,
      direction: theme.direction,
      '& svg.MuiSvgIcon-fontSizeSmall': {
        color: theme.palette.shade4,
      },
      '&.MuiAlert-colorInfo': {
        '& svg.MuiSvgIcon-fontSizeMedium': {
          color: `${theme.palette.commonBlueGradient2} !important`,
        },
      },
    },
    action: {
      margin: isRTL ? '0px auto 0px 0px' : '0px 0px 0px auto',
      padding: isRTL ? '0px 20px 0px 0px' : '0px 0px 0px 20px',
      marginLeft: 'auto',
      marginRight: 'auto',
      '@media screen and (max-width: 416px)': {
        marginRight: 'unset',
      },
    },
    cta: {
      width: 200,
      padding: '0 !important',
      height: 'unset !important',
      fontSize: `${theme.typography.pxToRem(10)} !important`,
    },
    filledInfo: {
      background: alpha(theme.palette.shade7, 0.9),
      color: theme.palette.shade2,
    },
    filledWarning: {
      '& a': {
        background: `${getPaletteModeStyles(theme.palette.mode)(theme.palette.shade7, theme.palette.shade6)} !important`,
      },
    },
    filledSuccess: {
      '& a': {
        background: `${getPaletteModeStyles(theme.palette.mode)(theme.palette.shade7, theme.palette.shade6)} !important`,
      },
    },
    filledError: {
      '& a': {
        background: `${getPaletteModeStyles(theme.palette.mode)(theme.palette.shade7, theme.palette.shade6)} !important`,
      },
    },
    closeButton: {
      '& svg': {
        color: `${theme.palette.shade0} !important`,
      },
    },
  });
});

export default useStyles;
