import * as React from 'react';
import { isValid } from 'date-fns';
import { useTranslation } from 'next-i18next';

export type TimeFormatKey = 'datetime' | 'date' | 'time' | 'utc' | 'timezone' | 'weekdaytime';

export const TimeFormat: Record<TimeFormatKey, Intl.DateTimeFormatOptions> = {
  datetime: {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  },
  date: {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  },
  time: {
    hour: '2-digit',
    minute: '2-digit',
  },
  utc: {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
    timeZone: 'UTC',
  },
  timezone: {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  },
  weekdaytime: {
    weekday: 'long',
    hour: '2-digit',
    minute: '2-digit',
  },
};

export interface TimeProps extends Omit<React.PropsWithRef<JSX.IntrinsicElements['time']>, 'dateTime'> {
  timestamp?: string | number | Date;
  format?: TimeFormatKey | Intl.DateTimeFormatOptions;
}

export const Time = React.forwardRef<HTMLTimeElement, TimeProps>(function Time(
  { timestamp, format: formatProp = TimeFormat.datetime, ...props },
  ref,
) {
  const { i18n } = useTranslation();

  const date = typeof timestamp === 'string' || typeof timestamp === 'number' ? new Date(timestamp) : timestamp;
  const format = typeof formatProp === 'string' ? TimeFormat[formatProp] : formatProp;

  return date && isValid(date) ? (
    <time {...props} dateTime={date.toISOString()} ref={ref}>
      {date.toLocaleString(i18n.language, format)}
    </time>
  ) : (
    <span {...props}>—</span>
  );
});
