import { useEffect, useState } from 'react';

const useLocalStorageKey = (keyName: string) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    const valueFromLocalStorage = localStorage.getItem(keyName);

    if (valueFromLocalStorage) {
      setValue(valueFromLocalStorage);
    }
  }, []);

  const handleSetKey = (value: string) => {
    setValue(value);
    localStorage.setItem(keyName, value);
  };

  return {
    value,
    handleSetKey,
  };
};

export default useLocalStorageKey;
