import * as React from 'react';
import { Stack } from '@mui/material';
import { clsx } from 'clsx';
import { useTranslation } from 'next-i18next';

import CreatorAlertComponent from '@/components/common/creator.alert';

import { useAuthSession } from '@/hooks/useAuthSession';
import { useCreatorProgramStatus } from '@/hooks/useCreatorProgramStatus';

import useCommonResponsiveStyles from '@/styles/common/responsive';

import useStyles from './banned.banner.styles';

export interface Props {
  wideLayout?: boolean;
}
export default function CouldBeBannedBanner({ wideLayout = false }: Props): JSX.Element {
  const { t } = useTranslation();
  const user = useAuthSession();
  const creatorProgramStatus = useCreatorProgramStatus();
  const banned = creatorProgramStatus?.banned;
  const alertBanned = {
    type: 'info',
    severity: 'error',
    messages: [t('welcome.alert.banned')],
    closeable: false,
    popup: false,
  };
  const classes = useStyles();
  const classesCommonResponsive = useCommonResponsiveStyles();

  return (
    <>
      {!!user && !!banned && (
        <Stack
          spacing={3}
          className={clsx(
            classes.alertsArea,
            { [classes.genericResponsive]: !wideLayout },
            { [classesCommonResponsive.responsive]: wideLayout },
          )}
        >
          <CreatorAlertComponent alert={alertBanned} />
        </Stack>
      )}
    </>
  );
}
