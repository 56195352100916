import { POSTS_PER_PAGE } from '@creator-portal/common/cms/constants';
import { CmsContentResponse } from '@creator-portal/common/types';

import * as Xhr from '@/util/xhr';

const xhr = Xhr.getInstance();

export interface ShortNews {
  title: string;
  _id: string;
  date: string;
  trendingImage?: string | null;
  image?: string | null;
  slug: string;
}

export const getCmsLink = (postsPerPage = POSTS_PER_PAGE, locale = 'en-US', offset = 0): string =>
  `/api/cms/v1/articles?postsPerPage=${postsPerPage}&offset=${offset}&locale=${locale}&rootPageSlug=blog-creator-portal`;

export const getResponseFromCms = async (
  locale = 'en-US',
): Promise<{ blogList: CmsContentResponse['blogList']; blogTotal: CmsContentResponse['blogTotal'] }> => {
  try {
    const blogsResponse = await xhr.fetchJson<CmsContentResponse>(getCmsLink(POSTS_PER_PAGE, locale), { method: 'GET' });
    Xhr.throwOnFailure(blogsResponse);

    const { blogList, blogTotal } = blogsResponse.data;

    return { blogList, blogTotal };
  } catch (error) {
    console.log('error while fetching news from CMS');

    return { blogList: [], blogTotal: 0 };
  }
};

export const getCreatorNewsArticles = async (locale: string): Promise<{ total: number; shortNews: ShortNews[] }> => {
  const blogsResponse = await getResponseFromCms(locale);
  const shortNews = blogsResponse.blogList.map((el) => ({
    title: el.title ?? null,
    _id: el._id ?? null,
    date: el.date ?? null,
    trendingImage: el.trendingImage ? el.trendingImage : null,
    image: el.image ? el.image : null,
    slug: el.slug ?? null,
  }));

  return {
    total: blogsResponse.blogTotal,
    shortNews,
  };
};
