import { alpha } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { ContentSpacing, WorkSansFont } from '@/components/common/main.layout.styles';

import type { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => {
  const isRTL = theme.direction === 'rtl';

  return createStyles({
    marginForArabian: {
      marginRight: theme.spacing(10),
      marginLeft: theme.spacing(10),
    },
    alertsArea: {
      marginTop: theme.spacing(4),

      padding: theme.spacing(0, 4.8, 0),

      [theme.breakpoints.up(theme.breakpoints.values.xs)]: {
        padding: theme.spacing(0, 8, 0),
      },
      [theme.breakpoints.up(theme.breakpoints.values.md)]: {
        padding: theme.spacing(0, 11.2, 0, 15),
      },
      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        padding: theme.spacing(0, 16, 0),
      },
      [theme.breakpoints.up(theme.breakpoints.values.xxl)]: {
        padding: theme.spacing(0, 24, 0),
      },
    },
    continueEnrollAlert: {
      background: `${alpha(theme.palette.shade7, 0.9)} !important`,
      '& .MuiTypography-root': {
        fontWeight: 400,
        color: theme.palette.shade2,
      },
    },
    welcomeBlock: {
      backgroundRepeat: 'no-repeat',
      backgroundPosition: isRTL ? 'left 50px top 95px' : 'right 10px top 95px',
      backgroundImage: `url(/${theme.palette.mode === 'light' ? 'light-welcome-page.webp' : 'dark-welcome-page.png'}) !important`,
      padding: theme.spacing(0, 4.8, 8, 4.8),
      maxWidth: '1840px',

      [theme.breakpoints.up(theme.breakpoints.values.xs)]: {
        padding: theme.spacing(0, 8, 8, 8),
      },

      [theme.breakpoints.up(theme.breakpoints.values.xl)]: {
        backgroundPosition: isRTL ? 'left 50px top 95px' : 'right 560px top 95px',
      },
      [theme.breakpoints.down(theme.breakpoints.values.xl)]: {
        backgroundImage: 'none !important',
      },
      [theme.breakpoints.up(theme.breakpoints.values.md)]: {
        padding: theme.spacing(0, 11.2, 8, 15),
        backgroundSize: '1900px',
        backgroundPosition: isRTL ? 'left -650px top 0' : 'right -390px top 0',
      },
      [theme.breakpoints.up(1700)]: {
        backgroundSize: '2012px',
        backgroundPosition: isRTL ? 'left -650px top 0' : 'right -175px top 0',
      },
      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        padding: theme.spacing(0, 16, 8, 16),
      },
      [theme.breakpoints.up(theme.breakpoints.values.xxl)]: {
        padding: theme.spacing(0, 24, 8, 24),
        [isRTL ? 'marginLeft' : 'marginRight']: 'auto',
      },
    },
    contentTopPadding: {
      paddingTop: theme.spacing(ContentSpacing.MOBILE),
      [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
        paddingTop: theme.spacing(ContentSpacing.TABLET),
      },
      [theme.breakpoints.up(theme.breakpoints.values.md)]: {
        paddingTop: theme.spacing(ContentSpacing.DESKTOP),
      },
    },
    top: {
      minHeight: '470px',
      textAlign: isRTL ? 'right' : 'left',
    },
    img: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    welcomeTitle: {
      fontFamily: WorkSansFont,
      fontWeight: '600',
      fontSize: theme.typography.pxToRem(21),
      lineHeight: theme.typography.pxToRem(28),
      letterSpacing: '-0.6px',
      color: theme.palette.shade0,
      marginBottom: theme.spacing(1),
    },
    titleWrapper: {
      maxWidth: '518px',
      [isRTL ? 'marginLeft' : 'marginRight']: 'auto',
    },
    title: {
      fontFamily: WorkSansFont,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(64),
      lineHeight: theme.typography.pxToRem(67),
      letterSpacing: '-0.6px',
      color: theme.palette.shade0,
      maxWidth: '490px',
      margin: isRTL ? theme.spacing(0, 0, 0, 'auto') : theme.spacing(0, 'auto', 0, 0),
      [theme.breakpoints.down(500)]: {
        fontSize: theme.typography.pxToRem(52),
        lineHeight: theme.typography.pxToRem(53),
      },
    },
    subTitle: {
      fontFamily: 'Inter',
      fontWeight: '600',
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(28),
      letterSpacing: '-0.6px',
      color: theme.palette.shade1,
      maxWidth: '518px',
      margin: isRTL ? theme.spacing(5.6, 0, 0, 'auto') : theme.spacing(5.6, 'auto', 0, 0),
    },
    btn: {
      display: 'inline-block !important',
      marginTop: theme.spacing(5.6),
      paddingRight: theme.spacing(10.4),
      paddingLeft: theme.spacing(10.4),
      '& span': {
        fontSize: theme.typography.pxToRem(20),
      },
    },
    tooltip: {
      color: theme.palette.shade7,
    },
    news: {
      display: 'grid',
      gridTemplateColumns: 'max-content',
      gridTemplateRows: '1fr 1fr',
      justifyContent: isRTL ? 'flex-end' : 'flex-start',
      textAlign: isRTL ? 'right' : 'left',
      gridGap: '20px',
      marginBottom: theme.spacing(7.8),

      [theme.breakpoints.down(500)]: {
        paddingTop: theme.spacing(10),
      },
      [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
        gridTemplateColumns: 'max-content max-content',
        gridTemplateRows: '1fr',
        alignItems: 'baseline',
      },
    },
    newsTitle: {
      fontFamily: 'Inter',
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(32),
      lineHeight: theme.typography.pxToRem(16),
      letterSpacing: '0.766892px',
      color: theme.palette.shade0,
    },
    newsExpand: {
      order: isRTL ? '-1' : '',
      '& a': {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(16),
        letterSpacing: '1.02252px',
        textTransform: 'uppercase',
        color: theme.palette.blue,
        textDecoration: 'none',
      },
      '& a:hover': {
        cursor: 'pointer',
        filter: theme.palette.brightnessUp,
        textDecoration: 'none',
      },
    },
  });
});

export default useStyles;
